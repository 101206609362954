body {
  max-width: 60em;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selected-waitlist, .unselected-waitlist {
    display: inline-block;
    border: 5px solid transparent;
    cursor: pointer;
}

.selected-waitlist {
    border-color: red;
}

.skill-domain-on-waitlist {
    background: repeating-linear-gradient(
      -45deg,
      white,
      white 10px,
      #ccc 10px,
      #ccc 20px
    );
}

/* sticky table headers */
thead {
    position: sticky;
    top: 0px;
    background-color: white;
    /* like the line below, but works with sticky */
    /* border-bottom: 2px solid black; */
    box-shadow: inset 0 -1px 0 black;
    /* fix “clear” button in date field staying about the thead element */
    z-index: 1;
}
tbody {
    /* cancel out the border set by bootstrap, since we want it on thead */
    border-top: none;
}

.big-checkbox {
    width: 25px;
    height: 25px;
}
